import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PremiumJobBoardsComponent from "./component";
import {
  IPremiumJobBoards,
  PREMIUM_ITEM_TYPES,
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../../models/SingleJob";
import {
  fetchJobApplications,
  fetchJobData,
  fetchJobDetails,
  fetchProductsAndPackages,
  resetProductsFilter,
  setPremiumJobBoardsTab,
  setSelectedProducts,
} from "../../../store/reducers/SingleJob";
import {
  getSingleJobFilteredProducts,
  getSingleJobFilteredShopProducts,
  getSingleJobState,
} from "../../../store/selectors/SingleJob";
import { setActiveProduct } from "../../../store/reducers/CurrentUser";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";

const PremiumJobBoards = ({
  isDiscoverPage,
  isJobEditorPage,
  isPremiumJobBoardsTab,
  onOpenCart,
}: IPremiumJobBoards) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key: id } = useParams();

  const [isSelectPackageButtonHovered, setIsSelectPackageButtonHovered] =
    useState<boolean>(false);

  const products = useSelector(getSingleJobFilteredProducts);
  const shopProducts = useSelector(getSingleJobFilteredShopProducts);

  const { isAgency } = useSelector(getCurrentUserData);
  const {
    shopPackages,
    packages,
    jobDetails,
    isLoading,
    totalShopProducts,
    selectedProducts,
    selectedPremiumJobBoardsTab,
  } = useSelector(getSingleJobState);

  const isTabs = isDiscoverPage || isJobEditorPage;

  const handleOnAddPackage = (jPackage: TPremiumPackage) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProducts([
        ...selectedProducts,
        {
          ...jPackage,
          name: jPackage.package_name,
          retail_price: jPackage.package_price,
          market_price: jPackage.package_price,
          recommended_retail_price: jPackage.oldPrice,
          discount_price: jPackage.package_price,
        },
      ])
    );
    onOpenCart();
  };

  const handleOnAddProduct = (item: TPremiumProductItem) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProducts([
        ...selectedProducts,
        {
          ...item,
          discount_price: item.recommended_retail_price - item.retail_price,
        },
      ])
    );
    onOpenCart();
  };

  const handleOnRemoveFromCart = (
    item: TPremiumPackage | TPremiumProductItem
  ) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedProducts(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedProducts.filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (selectedProduct: TPremiumPackage | TPremiumProductItem) =>
            selectedProduct.id !== item.id
        )
      )
    );
  };
  const isSelected = (currentItem: TPremiumProductItem | TPremiumPackage) => {
    return selectedProducts.some(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (item: TPremiumProductItem | TPremiumPackage) =>
        item.id === currentItem.id
    );
  };

  const onResetFilters = () => dispatch(resetProductsFilter());

  const onOpenProduct = (
    product: TConvertedPremiumProduct | TPremiumPackage
  ) => {
    if (isDiscoverPage) {
      dispatch(
        setActiveProduct(product as unknown as TConvertedPremiumProduct)
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (product.package_name) {
        if (!isSelectPackageButtonHovered) {
          navigate("/package");
        }
      } else {
        navigate("/product");
      }
    }
  };

  const handleHoverLeaveSelectPackageButton = (value: boolean) => {
    setIsSelectPackageButtonHovered(value);
  };

  const onSuccessFetchJobApplications = (urlKey: string) => {
    dispatch(
      fetchJobDetails({
        jobUrlKey: urlKey,
      })
    );
  };

  useEffect(() => {
    if (!isAgency && !isDiscoverPage) {
      dispatch(
        fetchJobApplications({
          jobId: id as string,
          callback: (urlKey: string) => onSuccessFetchJobApplications(urlKey),
        })
      );
    }
    if (id && isAgency) {
      dispatch(
        fetchJobData({
          jobId: id,
          callback: () => {},
        })
      );
    }
  }, [jobDetails?.url_key]);

  useEffect(() => {
    if (jobDetails?.url_key) {
      dispatch(
        fetchProductsAndPackages({
          urlKey: jobDetails.url_key,
        })
      );
    }
  }, [jobDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetProductsFilter());
    };
  }, []);

  const handleOnSelectTab = (
    _: React.SyntheticEvent,
    value: PREMIUM_ITEM_TYPES
  ) => {
    dispatch(setPremiumJobBoardsTab(value));
  };

  return (
    <PremiumJobBoardsComponent
      t={t}
      isLoading={isLoading}
      packages={isDiscoverPage ? shopPackages : packages}
      products={isDiscoverPage ? shopProducts : products}
      selectedProducts={selectedProducts}
      totalShopProducts={totalShopProducts}
      isDiscoverPage={isDiscoverPage}
      isJobEditorPage={isJobEditorPage}
      selectedTab={selectedPremiumJobBoardsTab}
      isPremiumJobBoardsTab={Boolean(isPremiumJobBoardsTab)}
      selectedPremiumJobBoardsTab={selectedPremiumJobBoardsTab}
      isTabs={Boolean(isTabs)}
      isAgency={Boolean(isAgency)}
      onOpenCart={onOpenCart}
      isSelected={isSelected}
      onSelectTab={handleOnSelectTab}
      onAddPackage={handleOnAddPackage}
      onAddProduct={handleOnAddProduct}
      onRemoveFromCart={handleOnRemoveFromCart}
      onOpenProduct={onOpenProduct}
      onResetFilters={onResetFilters}
      onHoverLeaveSelectPackageButton={handleHoverLeaveSelectPackageButton}
    />
  );
};

export default PremiumJobBoards;
