import React from "react";
import { Box, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import MultipleAutocompleteFilterFreeSolo from "../../filters/MultipleAutocompleteFilterFreeSolo";
import { StyledSubSectionContainer } from "../styles";
import { IJobDetailsShortAdditionalInfoSection } from "../../../models/JobEditor";

const ShortAdditionalInfoSection = ({
  t,
  control,
  campaignTags,
  register,
}: IJobDetailsShortAdditionalInfoSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_second_step.additional_information")}
    </Typography>
    <StyledSubSectionContainer spacing={2}>
      <Stack spacing={2}>
        <Stack sx={{ flex: 1 }}>
          <InputLabel>{t("create_job_second_step.keywords")}</InputLabel>
          <TextField
            placeholder={t("create_job_second_step.keywords") as string}
            fullWidth
            {...register("keywords")}
          />
        </Stack>
        <Box sx={{ flex: 0.5 }} pr={2}>
          <InputLabel>{t("create_job_second_step.campaign_tag")}</InputLabel>
          <Controller
            name={"campaign_tags"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultipleAutocompleteFilterFreeSolo
                onChange={onChange}
                value={value || []}
                options={campaignTags}
                noOptionsText={t("create_job_second_step.add_tag") as string}
                placeholder={t("create_job_second_step.campaign_tag") as string}
              />
            )}
          />
        </Box>
      </Stack>
    </StyledSubSectionContainer>
  </>
);

export default ShortAdditionalInfoSection;
