import React from "react";
import { Tooltip } from "@mui/material";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const IconWithTooltip = ({ title }: { title: string }) => (
  <Tooltip
    arrow
    placement={"right"}
    title={title}
    componentsProps={{
      arrow: {
        sx: {
          color: "#178CF2",
        },
      },
      tooltip: {
        sx: {
          maxWidth: 300,
          fontSize: "12px",
          padding: 1,
          background: "#178CF2",
        },
      },
    }}
  >
    <InformationCircleIcon />
  </Tooltip>
);

export default IconWithTooltip;
