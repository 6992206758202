import React from "react";
import {
  ArrowLeftIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  StyledCheckoutSectionContainer,
  StyledContainer,
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledLink,
  StyledPriceContainer,
  StyledProductItemContainer,
  StyledRemoveButtonContainer,
  StyledTermsConditionsContainer,
  StyledVerticalDivider,
} from "./styles";
import { ICheckoutComponent } from "../../models/JobEditor";
import { formatSalaryValue } from "../../utils";

const CheckoutComponent = ({
  t,
  total,
  oldTotal,
  agencyTotal,
  discount,
  selectedProducts,
  agreement,
  isLoading,
  isAgency,
  onCheckAgreement,
  onPublish,
  onClose,
  onRedirectPrivacy,
  onRedirectTerms,
  onRemoveItem,
}: ICheckoutComponent) => (
  <StyledContainer>
    <Stack pb={4} spacing={2} direction={"row"}>
      <ArrowLeftIcon
        onClick={onClose}
        width={24}
        height={24}
        cursor={"pointer"}
      />
      <Typography variant="body1" color="#0F141B">
        {t("create_job_fifth_step.confirmation")}
      </Typography>
    </Stack>
    <Stack direction="row" spacing={3}>
      <StyledCheckoutSectionContainer flex={2}>
        <Typography fontSize={18} color={"#0F141B"} fontWeight={500}>
          {t("create_job_fifth_step.selected_products")}
        </Typography>
        <Divider />
        <Stack spacing={2}>
          {selectedProducts.map((item: any, index: number) => (
            <StyledProductItemContainer
              key={index}
              direction={"row"}
              spacing={1}
            >
              <Stack width="25%" justifyContent="center" alignItems="center">
                {item.image_url ? (
                  <img src={item.image_url} alt="img" width={120} height={40} />
                ) : (
                  <Typography variant="body1">
                    {item.supplier || item.name || item.package_name}
                  </Typography>
                )}
              </Stack>
              <Stack width="55%" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1.25}>
                  <Tooltip title={item.name}>
                    <Typography variant="body2" noWrap fontWeight={400}>
                      {item.name || item.package_name}
                    </Typography>
                  </Tooltip>
                  {item.discount && item.discount > 0 ? (
                    <StyledDiscountLabel>{item.discount}%</StyledDiscountLabel>
                  ) : null}
                </Stack>
                {item.runtime_in_days && (
                  <StyledDaysBlock
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <ClockIcon width={24} height={24} />
                    <span>{item.runtime_in_days} days</span>
                  </StyledDaysBlock>
                )}
              </Stack>
              {item.package_name ? (
                <StyledPriceContainer direction={"column"} mt={0}>
                  {item.agency_price ? (
                    item.agency_price < item.package_price ? (
                      <Box className={"old-price"}>
                        {formatSalaryValue(item.agency_price)} €
                      </Box>
                    ) : null
                  ) : item.package_price < item.oldPrice ? (
                    <Box className={"old-price"}>{item.oldPrice} €</Box>
                  ) : null}
                  <Box className={"new-price"}>
                    {formatSalaryValue(item.agency_price) ||
                      formatSalaryValue(item.package_price)}{" "}
                    €
                  </Box>
                </StyledPriceContainer>
              ) : (
                <StyledPriceContainer direction={"column"} mt={0}>
                  {item.agency_price ? (
                    item.agency_price < item.recommended_retail_price ? (
                      <Box className={"old-price"}>
                        {formatSalaryValue(item.recommended_retail_price)} €
                      </Box>
                    ) : null
                  ) : item.retail_price < item.recommended_retail_price ? (
                    <Box className={"old-price"}>
                      {formatSalaryValue(item.recommended_retail_price)} €
                    </Box>
                  ) : null}
                  <Box className={"new-price"}>
                    {isAgency
                      ? formatSalaryValue(item.agency_price)
                      : formatSalaryValue(item.retail_price)}{" "}
                    €
                  </Box>
                </StyledPriceContainer>
              )}
              <StyledVerticalDivider orientation={"vertical"} />
              <StyledRemoveButtonContainer
                direction="row"
                justifyContent="flex-end"
              >
                <XCircleIcon onClick={() => onRemoveItem(item)} />
              </StyledRemoveButtonContainer>
            </StyledProductItemContainer>
          ))}
        </Stack>
      </StyledCheckoutSectionContainer>
      <StyledCheckoutSectionContainer flex={1}>
        <Typography fontSize={18} color={"#0F141B"} fontWeight={500}>
          {t("create_job_fifth_step.summary")}
        </Typography>
        <Divider />
        {!!isAgency && (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">
              {t("create_job_fifth_step.original_price")}
            </Typography>
            <Typography variant="body2">
              {formatSalaryValue(oldTotal)} €
            </Typography>
          </Stack>
        )}
        <Stack
          mt={isAgency ? 3 : 0}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="body2">
            {isAgency
              ? t("create_job_fifth_step.agency_price")
              : t("create_job_fifth_step.original_price")}
          </Typography>
          <Typography variant="body2">
            {isAgency
              ? formatSalaryValue(agencyTotal)
              : formatSalaryValue(oldTotal)}{" "}
            €
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" mt={3}>
          <Typography variant="body2">
            {t("create_job_fifth_step.discount")}
          </Typography>
          <Typography variant="body2">
            {discount == 0 ? 0 : formatSalaryValue(discount)} €
          </Typography>
        </Stack>

        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">
            {t("create_job_fifth_step.sub_total")}
          </Typography>
          <Typography variant="body2">
            {isAgency
              ? formatSalaryValue(agencyTotal)
              : formatSalaryValue(oldTotal)}{" "}
            €
          </Typography>
        </Stack>

        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" fontWeight={800} fontSize={20}>
            {t("create_job_fifth_step.total")}
          </Typography>
          <Typography variant="body1" fontWeight={800} fontSize={20}>
            {isAgency
              ? formatSalaryValue(agencyTotal - discount)
              : formatSalaryValue(total)}{" "}
            €
          </Typography>
        </Stack>
        <Divider />
        {!!isAgency && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.customer_total")}
              </Typography>
              <Typography variant="body2">{total} €</Typography>
            </Stack>
            <Stack mt={3} direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.margin")}
              </Typography>
              <Typography variant="body2">
                {total - agencyTotal + discount} €
              </Typography>
            </Stack>
            <Divider />
          </>
        )}
        <Stack direction="row">
          <Stack>
            <Checkbox
              checked={agreement}
              color="secondary"
              onChange={onCheckAgreement}
            />
          </Stack>
          <Stack>
            <Typography variant="subtitle2" color="textSecondary">
              {t("create_job_fifth_step.termsFirst")}{" "}
              <StyledLink onClick={onRedirectTerms}>
                {t("create_job_fifth_step.termsSecond")}
              </StyledLink>{" "}
              {t("create_job_fifth_step.termsThird")}{" "}
              <StyledLink onClick={onRedirectPrivacy}>
                {t("create_job_fifth_step.termsFour")}{" "}
              </StyledLink>
              {t("create_job_fifth_step.termsFive")}
            </Typography>
          </Stack>
        </Stack>
        <Stack mt={3}>
          <Button
            endIcon={
              isLoading ? (
                <CircularProgress
                  size={16}
                  style={{
                    color: "#9A9FAF",
                  }}
                />
              ) : null
            }
            color="primary"
            variant="contained"
            disabled={isLoading || !agreement || !selectedProducts?.length}
            onClick={onPublish}
          >
            {t("create_job_fifth_step.place_your_order")}
          </Button>
        </Stack>
      </StyledCheckoutSectionContainer>
    </Stack>
    <Stack spacing={3} justifyContent={"center"} marginTop={"auto"}>
      <Divider />
      <StyledTermsConditionsContainer
        direction={"row"}
        spacing={3}
        fontSize={14}
      >
        <StyledLink onClick={onRedirectTerms}>{t("terms")}</StyledLink>
        <StyledLink onClick={onRedirectPrivacy}>{t("privacy")}</StyledLink>
      </StyledTermsConditionsContainer>
    </Stack>
  </StyledContainer>
);

export default CheckoutComponent;
