import { PayloadAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { CSSProperties, ReactNode, RefObject, SyntheticEvent } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

export interface IChipsManager {
  readonly chipList?: string[];
  readonly placeholder?: ReactNode;
  readonly showListAlways?: boolean;
  readonly listHeight?: number;
  readonly validation?: {
    readonly validationFunc: (val: string) => boolean;
    readonly errorMessage: string;
  };
  readonly onChange: (chipList: string[]) => void;
}

export interface IChipsManagerComponent {
  readonly chip: string;
  readonly chipList?: string[];
  readonly placeholder?: ReactNode;
  readonly errorMessage?: string;
  readonly showListAlways?: boolean;
  readonly listHeight?: number;
  readonly onAdd: () => void;
  readonly onChange: (value: string) => void;
  readonly onDelete: (chipIndex: number) => void;
}

export interface IExpandedBlock {
  readonly t: TFunction;
  readonly title?: string;
  readonly body?: string;
}

export interface IExpandedBlockComponent {
  readonly t: TFunction;
  readonly title?: string;
  readonly body?: string;
  bodyRef: RefObject<HTMLElement>;
  isLongText: boolean;
  isExpanded: boolean;
  isRendered: boolean;
  onExpand: () => void;
}

export type TListOption = {
  readonly label: string;
  readonly value: string;
};

export type TLanguageListOption = {
  readonly key: string;
  readonly label: string;
  readonly value: string;
};

export type TStatusListOption = {
  readonly label: string;
  readonly value: string;
  readonly color: string;
};

export type TWorkflowListOption = {
  readonly label: string;
  readonly value: string;
  readonly jobId: string;
};

export type TCountryListOption = {
  readonly label: string;
  readonly value: string;
  readonly iso: string;
};

export type TAgencyListOption = {
  readonly label: string;
  readonly value: string;
  readonly companyId: number;
};

export type TTwoValidationTextFields = {
  readonly desktop?: boolean;
  readonly background?: string;
  readonly padding?: number | string;
  readonly spacing?: number;
  readonly labels: string[];
  readonly registers: string[];
  readonly flex?: number[];
  readonly placeholders: string[];
  readonly errors: FieldErrors<any>;
  readonly register: UseFormRegister<any>;
};

export type TPagination = {
  readonly desktop?: boolean;
  readonly page: number;
  readonly itemsPerPage: number;
  readonly totalPosts: string | number;
  readonly paginate: (page: number) => void;
};

export type TRenderPagination = {
  readonly t: TFunction;
  readonly page: number;
  readonly indexOfFirstItem: number;
  readonly indexOfLastItem: number;
  readonly itemsPerPage: number;
  readonly totalPosts: number;
  readonly paginate: (page: number) => void;
};

export type TBigColorPicker = {
  readonly color: string;
  readonly title: string;
  readonly value?: string;
  readonly customStyle: CSSProperties;
  readonly isDefaultColor?: boolean;
  readonly onChange?: (data: string) => void;
};

export type TColorPicker = {
  readonly color: string;
  readonly title: string;
  readonly value?: string;
  readonly customStyle: CSSProperties;
  readonly hideColorText?: boolean;
  readonly isDefaultColor?: boolean;
  readonly customTextValue?: string;
  readonly onChange?: (data: string) => void;
  readonly onChangeName?: (value: string) => void;
};

export type TEmptyData = {
  readonly title: string;
  readonly subtitle?: string;
  readonly buttonText?: string;
  readonly onButtonClick?: () => void;
};

export type TAutocompleteFilter = {
  readonly value: string | string[] | TListOption[];
  readonly options: TListOption[] | TAgencyListOption[];
  readonly placeholder: string;
  readonly hasFlags?: boolean;
  readonly hasColors?: boolean;
  readonly errors?: { message?: string };
  readonly multiple?: boolean;
  readonly size?: "small" | "medium";
  readonly filterOptions?: any;
  readonly listBoxProps?: any;
  readonly disableClearable?: boolean;
  readonly onChange: (data: string | string[]) => void;
};

export type TMultipleAutocompleteFilterFreeSolo = {
  readonly value?: string | string[] | TListOption[];
  readonly options: TListOption[];
  readonly placeholder: string;
  readonly errors?: { message?: string };
  readonly noOptionsText: string;
  readonly onChange: (data: string[]) => void;
};

export type TImageUpload = {
  readonly image?: File | string | null;
  readonly title: string;
  readonly subtitle: string;
  readonly isCareerPage?: boolean;
  readonly onChange?: (file: File | undefined) => void;
};

export type TLoading = {
  readonly color?: "primary" | "secondary" | "info" | "success" | "warning";
  readonly height?: number | string;
  readonly size?: number;
};

export type TMultipleTextfields = {
  readonly items: {
    placeholder: string;
    inputLabel: string;
    value: string;
    flexRatio: number;
  }[];
};

export type TNoData = {
  readonly title: string;
  readonly headings?: {
    text: string;
    link?: string;
  }[];
  readonly subtitles?: string[];
  readonly containerHeight?: string | number;
  readonly noButton?: boolean;
};

export type TProfilePicture = {
  readonly imgSrc?: string;
  readonly type?: "rounded" | "circular" | "square";
  readonly onDelete: () => void;
  readonly onOpenUploadDialog: () => void;
};

export type TStyledSectionTitle = {
  readonly title: string;
  readonly onClick?: () => void;
  readonly onClose?: () => void;
};

export type TTextEditor = {
  ref?: any;
  value?: string;
  errorMessage?: string;
  onChange: (data: string) => void;
};

export type TTextEditorCustomToolbar = {
  readonly items: TListOption[];
  readonly value?: string;
  readonly errorMessage?: string;
  readonly isOnlyVariables?: boolean;
  readonly onChange: (data: string) => void;
};

export type TUploadDocument = {
  readonly placeholder: string;
  readonly value: string;
  readonly companyColor?: string;
  readonly onChange: (file?: File) => void;
};

export type TSidebar = {
  readonly t: TFunction;
  readonly expandedMenuItems: string[];
  readonly isCandidate: boolean;
  readonly isOpenMenu: boolean;
  readonly isLoading: boolean;
  readonly menuItems: any;
  readonly selectedMenuItem: string;
  readonly desktop: boolean;
  readonly mobileDrawerOpen: boolean;
  readonly isScrolling: boolean;
  readonly onMobileDrawerClose: () => void;
  readonly onExpandMenuItem: (event: SyntheticEvent, nodeId: string[]) => void;
  readonly onNavigateMenu: (route: string) => void;
  readonly onOpenJobEditor: () => void;
  readonly onSelectMenuItem: (event: SyntheticEvent, nodeId: string) => void;
  readonly onToggleMenu: () => void;
};

export type TAdditionalQuestionsHeader = {
  readonly t: TFunction;
  readonly companyTitle: string;
  readonly languages: [];
  readonly logo: string | null;
  readonly language: string;
  readonly desktop: boolean;
  readonly onChangeLanguage: (data: string) => void;
};

export type TAdditionalQuestionsJobInfo = {
  readonly desktop: boolean;
  readonly location: string;
  readonly title: string;
  readonly type: string;
  readonly position: string;
  readonly companyColor: string;
};

export enum DIALOG_TYPE {
  CONNECTED = "connected",
  DELETE = "delete",
  DELETE_JOB = "deleteJob",
  INVITE = "invite",
  ARCHIVE_RESTORE = "archiveRestore",
  UPLOAD_IMAGE = "uploadImage",
  DISCONNECT = "disconnect",
  SYNC_CUSTOMERS = "syncCustomers",
  CREATE_EDIT_TALENT_POOL = "createEditTalentPool",
  DELETE_TALENT_POOL = "deleteTalentPool",
  ADD_CANDIDATE_TO_JOB = "addCandidateToJob",
  APPLY_JOB = "applyJob",
  CHANGE_PASSWORD = "changePassword",
  ADD_PROFILE = "addProfile",
}

export type PayloadActionWithCallback<T> = T extends undefined
  ? PayloadAction<{
      callback: (data?: any) => void;
    }>
  : PayloadAction<T & { callback: (data?: any) => void }>;
