import React, { SyntheticEvent, useState } from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { StyledInputContainerMultipleValues } from "../styles";
import { StyledAutocompleteChip } from "../AutocompleteFilter/style";
import {
  TListOption,
  TMultipleAutocompleteFilterFreeSolo,
} from "../../../models/common";

const MultipleAutocompleteFilterFreeSolo = ({
  value,
  options,
  noOptionsText,
  errors,
  placeholder,
  onChange,
}: TMultipleAutocompleteFilterFreeSolo) => {
  const [inputValue, setInputValue] = useState("");

  const getFilterOptions = (
    options: { label: string; value: string }[],
    params: any
  ) => {
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(params.inputValue.toLowerCase())
    );

    if (
      params.inputValue !== "" &&
      !filtered.some(
        (option) =>
          option.label.toLowerCase() === params.inputValue.toLowerCase()
      )
    ) {
      filtered.unshift({
        label: params.inputValue,
        value: crypto.randomUUID(),
      });
    }
    return filtered;
  };

  const handleAutocompleteChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: TListOption[]
  ) => {
    const formattedValue = newValue.map((item) => {
      if (typeof item === "string") {
        return {
          value: uuidv4(),
          label: item,
        };
      }
      return item;
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange(formattedValue);
  };

  return (
    <Autocomplete
      sx={{
        "& .autocomplete-multiline-tag-container": {
          display: "flex !important",
          flexWrap: "wrap",
          gap: "4px",
          flexDirection: "row !important",
        },
      }}
      ListboxProps={{
        className: "multiple-selection-autocomplete",
      }}
      multiple
      size="small"
      freeSolo
      value={value as { label: string; value: string }[]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleAutocompleteChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      filterOptions={getFilterOptions}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <StyledInputContainerMultipleValues>
          <TextField
            {...params}
            error={Boolean(errors)}
            multiline
            placeholder={placeholder}
          />
        </StyledInputContainerMultipleValues>
      )}
      renderTags={(tagValue, getTagProps) => (
        <Stack className="autocomplete-multiline-tag-container">
          {tagValue.map((option, index) => (
            <StyledAutocompleteChip
              size="small"
              {...getTagProps({ index })}
              key={option.value}
              label={option.label}
            />
          ))}
        </Stack>
      )}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const isCustomValue = !options.some(
          (opt) => opt.label === option.label
        );
        return (
          <li {...props}>
            {isCustomValue
              ? `${noOptionsText} "${option.label}"`
              : option.label}
          </li>
        );
      }}
    />
  );
};

export default MultipleAutocompleteFilterFreeSolo;
