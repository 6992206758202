import React from "react";
import { Divider, Stack } from "@mui/material";
import AdditionalInfoSection from "./AdditionalInfoSection";
import GeneralSection from "./GeneralSection";
import LocationsSection from "./LocationsSection";
import SalarySection from "./SalarySection";
import StyledSectionTitle from "../../SectionTitle";
import { IJobDetailsFormComponent } from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import { StyledJobDialogFormSection } from "../styles";
import ShortAdditionalInfoSection from "./ShortAdditionalInfoSection";

const JobDetailsFormComponent = ({
  t,
  errors,
  control,
  locations,
  countries,
  contractTypes,
  jobTypes,
  positionTypes,
  seniority,
  industries,
  qualifications,
  salaryCurrencies,
  workFields,
  salaryTypes,
  formRef,
  companyMembers,
  selectedSections,
  filterOptions,
  locale,
  campaignTags,
  register,
  onAddLocation,
  onRemoveLocation,
  handleSubmit,
  onSubmit,
}: IJobDetailsFormComponent) => (
  <StyledJobDialogFormSection spacing={2}>
    <StyledSectionTitle title={t("create_job_first_step.specify_job")} />
    <Divider />
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Stack spacing={1.5}>
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_DETAILS_LOCATIONS
        ) && (
          <LocationsSection
            t={t}
            errors={errors}
            control={control}
            locations={locations}
            countries={countries}
            jobTypes={jobTypes}
            filterOptions={filterOptions}
            register={register}
            onAddLocation={onAddLocation}
            onRemoveLocation={onRemoveLocation}
          />
        )}
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_DETAILS_GENERAL_INFO
        ) && (
          <GeneralSection
            t={t}
            errors={errors}
            control={control}
            contractTypes={contractTypes}
            workFields={workFields}
            positionTypes={positionTypes}
            seniority={seniority}
            industries={industries}
            qualifications={qualifications}
          />
        )}
        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_DETAILS_ADDITIONAL_INFO
        ) && (
          <AdditionalInfoSection
            t={t}
            errors={errors}
            control={control}
            locale={locale}
            companyMembers={companyMembers}
            campaignTags={campaignTags}
            register={register}
          />
        )}

        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_DETAILS_SHORT_ADDITIONAL_INFO
        ) && (
          <ShortAdditionalInfoSection
            t={t}
            control={control}
            campaignTags={campaignTags}
            register={register}
          />
        )}

        {selectedSections.includes(
          SELECTED_EDIT_SECTIONS.JOB_DETAILS_SALARY
        ) && (
          <SalarySection
            t={t}
            control={control}
            salaryCurrencies={salaryCurrencies}
            salaryTypes={salaryTypes}
          />
        )}
      </Stack>
    </form>
  </StyledJobDialogFormSection>
);

export default JobDetailsFormComponent;
