import moment from "moment";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
  TPremiumProducts,
  TSalesProduct,
} from "../models/SingleJob";

export const getPackagesWithDiscount = (packages: TPremiumPackage[]) =>
  packages.map((jPackage: TPremiumPackage) => {
    const oldPrice = jPackage.sales_products.reduce(
      (acc: number, item: TSalesProduct) => acc + item.rrp,
      0
    );
    const discount = Math.round(
      100 - (jPackage.package_price / oldPrice) * 100
    );

    return { ...jPackage, discount, oldPrice };
  });

export const getConvertedProducts = (
  isAgency: boolean,
  products: TPremiumProducts
): TConvertedPremiumProduct[] =>
  Object.keys(products).reduce(
    (acc: TConvertedPremiumProduct[], productKey: string) => [
      ...acc,
      {
        name: products[productKey][0].supplier,
        country: products[productKey][0].country,
        products: getSortedProductsWithDiscount(isAgency, products[productKey]),
      },
    ],
    []
  );

export const getSortedProductsWithDiscount = (
  isAgency: boolean,
  products: TPremiumProductItem[]
) =>
  products
    .map((product) => {
      const discount =
        isAgency && product.agency_price
          ? Math.round(
              100 -
                (product.agency_price / product.recommended_retail_price) * 100
            )
          : Math.round(
              100 -
                (product.retail_price / product.recommended_retail_price) * 100
            );
      return { ...product, discount };
    })
    .sort((a, b) => a.retail_price - b.retail_price);

export const daysBeetwen = (firstDate: string, secondDate?: string) => {
  const formatedFirstDate = moment(firstDate);
  const formatedSecondDate = secondDate ? moment(secondDate) : moment();
  const days = formatedSecondDate.diff(formatedFirstDate, "days");
  return Math.abs(days);
};

export const isEmptyHtmlString = (htmlString: string) => {
  const parser = new DOMParser();

  const { textContent } = parser.parseFromString(
    htmlString,
    "text/html"
  ).documentElement;

  return !textContent?.trim();
};
