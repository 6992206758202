import React, { useState, useEffect, useMemo } from "react";
import { createFilterOptions } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import JobDetailsFormComponent from "./component";
import {
  IJobDetailsForm,
  TJobDetailsFormFields,
} from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import {
  getJobEditorJobDataState,
  getJobEditorState,
} from "../../../store/selectors/JobEditor";
import {
  getJobDetailsFormFields,
  jobDetailsFormDefaultValues,
} from "../config";
import { TListOption } from "../../../models/common";
import { fetchCampaignTags } from "../../../store/reducers/JobEditor";

const JobDetailsForm = ({
  t,
  defaultValues,
  formRef,
  selectedSections = [
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_LOCATIONS,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_GENERAL_INFO,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_ADDITIONAL_INFO,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_SALARY,
  ],
  onSubmit,
  onChangeForm,
}: IJobDetailsForm) => {
  const dispatch = useDispatch();

  const [locale, setLocale] = useState("de");
  const {
    control,
    formState: { errors },
    watch,
    register,
    getValues,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getJobDetailsFormFields(t)),
    defaultValues: defaultValues || jobDetailsFormDefaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });

  const {
    countries,
    contractTypes,
    workFields,
    positionTypes,
    seniorities: seniority,
    industriesList: industries,
    qualificationsList: qualifications,
    salaryCurrencies,
    salaryTypes,
    companyMembers,
    jobTypes,
  } = useSelector(getJobEditorJobDataState);
  const { campaignTags } = useSelector(getJobEditorState);

  const formattedCampaignTags = useMemo(() => {
    if (campaignTags.length) {
      const tags = campaignTags?.map((tag) => ({
        value: tag,
        label: tag,
      }));
      return tags;
    }
    return [];
  }, [campaignTags]);

  const formatedCountries = countries.map((item: any) => ({
    label: item.label.Label,
    value: item.label.Id,
  }));

  const filterOptions = createFilterOptions({
    stringify: (option: TListOption) => option.label,
    ignoreCase: true,
    matchFrom: "any",
    limit: 10,
  });

  const handleOnSubmit = (data: TJobDetailsFormFields) => {
    const campaignTags = getValues().campaign_tags.map((tag) => tag.label);
    onSubmit({
      ...data,
      min_salary: data.min_salary.replace(".", ""),
      max_salary: data.max_salary.replace(".", ""),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      campaign_tags: campaignTags,
    });
  };

  const handleOnAddLocation = () => {
    append({ city: "", country: "", zip: "" });
  };

  const handleOnRemoveLocation = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (onChangeForm) {
      const subscription = watch((value) => {
        onChangeForm(value as TJobDetailsFormFields);
      });
      return () => subscription.unsubscribe();
    }
  }, [watch]);

  useEffect(() => {
    const language = localStorage.getItem("lang") || "de_DE";
    if (language === "en_US") {
      setLocale("en");
    }
    if (!getValues().min_salary) {
      setValue("min_salary", "");
    }
    if (!getValues().max_salary) {
      setValue("max_salary", "");
    }

    dispatch(fetchCampaignTags());
  }, []);

  return (
    <JobDetailsFormComponent
      t={t}
      filterOptions={filterOptions}
      errors={errors}
      control={control}
      locations={fields}
      countries={formatedCountries}
      contractTypes={contractTypes}
      jobTypes={jobTypes}
      workFields={workFields}
      positionTypes={positionTypes}
      seniority={seniority}
      industries={industries}
      qualifications={qualifications}
      salaryCurrencies={salaryCurrencies}
      salaryTypes={salaryTypes}
      companyMembers={companyMembers}
      formRef={formRef}
      selectedSections={selectedSections}
      locale={locale}
      campaignTags={formattedCampaignTags}
      register={register}
      onAddLocation={handleOnAddLocation}
      onRemoveLocation={handleOnRemoveLocation}
      onSubmit={handleOnSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default JobDetailsForm;
